* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: #454545;
  background-color: #f1f5f9;
  font: 500 14px 'Inter', sans-serif;
  letter-spacing: -0.009em;
}

body.auth {
  background-color: #fff;
}

a {
  color: #242424;
}