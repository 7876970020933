@font-face {
  font-family: 'Inter', sans-serif;
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://assets.tweeker.io/fonts/Inter-Medium.woff2") format("woff2"),
       url("https://assets.tweeker.io/fonts/Inter-Medium.woff") format("woff");
}

@font-face {
  font-family: 'Inter', sans-serif;
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://assets.tweeker.io/fonts/Inter-SemiBold.woff2") format("woff2"),
       url("https://assets.tweeker.io/fonts/Inter-SemiBold.woff") format("woff");
}